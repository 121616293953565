.ten_jumbotron {
    font-family: "Libre Baskerville", serif;
    position: relative;
    color: #fff;
    height: 8vh;
  }
  .ten_jumbotronBackgroundColor {
    background: #c8ac48;
    background-repeat: repeat;
  }



  @media screen and (max-width: 480px) {
  .ten_jumbotron {
    font-size: 14px;
  }
}
