.ninth_jumbotronBackGroundImage {
    background: url("../images/dark_pattern.png");
    background-repeat: repeat;
  }
  .ninth_jumbotron {
    font-family: "Libre Baskerville", serif;
    position: relative;
    color: #fff;
    height: 55vh;
    /* border: 1px solid blue; */
    /* padding: 5%; */
  }
  .spaceAround {
    /* border: 1px solid gold; */
    margin: 10% auto;
    margin-left: 15%;
    margin-top: 20%;
  }
  .ninthJumbotronColHeader1 {
    /* border: 1px solid gold; */
    width: fit-content;
    cursor: pointer;
    font-family: "Libre Baskerville", serif;
    font-size: 23px;
    position: relative;
    opacity: 1;
    margin-bottom: 4%;
  }
  
  .ninthJumbotronColHeader2 {
    /* border: 1px solid gold; */
    width: fit-content;
    cursor: pointer;
    font-family: "Libre Baskerville", serif;
    font-size: 23px;
    position: relative;
    opacity: 1;
    margin-bottom: 15%;
  }
  .ninthJumbotronColHeader2:before {
    width: 45%;
    content: "";
    position: absolute;
    height: 10px;
    top: 100%;
    left: 0%;
    border-bottom: 2px solid #c8ac48;
  }
  .ninthJumbotronColHeader3 {
    /* border: 1px solid gold; */
    width: fit-content;
    cursor: pointer;
    font-family: "Libre Baskerville", serif;
    font-size: 23px;
    position: relative;
    opacity: 1;
    margin-bottom: 15%;
  }
  .ninthJumbotronColHeader3:before {
    width: 45%;
    content: "";
    position: absolute;
    height: 10px;
    top: 100%;
    left: 0%;
    border-bottom: 2px solid #c8ac48;
  }
  .ninthJumbotronColHeader:hover {
    color: #c8ac48;
  }
  .ninthJumbotronColPara {
    /* border: 1px solid green; */
    font-family: sans-serif;
    font-size: 16px;
    color: grey;
  }
  .ninthJumbotronColPara1 {
    /* border: 1px solid red; */
    font-family: sans-serif;
    font-size: 16px;
    color: grey;
  }
  .ninthJumbotronColPara2 {
    /* border: 1px solid red; */
    font-family: sans-serif;
    font-size: 16px;
    color: grey;
    /* width: 100%; */
    /* margin-bottom: 10%; */
  }

  /* =================================== */
/* ---------------768--PX----------- */
/* =================================== */
@media screen and (max-width: 768px) {
    .ninth_jumbotron {
        height: fit-content;
      }
       .spaceAround {
        margin-left: 5%;
      }
      
}

  /* =================================== */
/* ---------------480--PX----------- */
/* =================================== */
/* @media screen and (max-width: 480px) {
.ninth_jumbotron {
    height: max-content;
  }
  .spaceAround {
    margin: 0% auto;
    margin-left: 5%;
    margin-top: 7%;
  }
} */