/*----seventh section-----*/
.seventh_jumbotron {
    /* border: 1px solid white; */
    font-family: "Libre Baskerville", serif;
    position: relative;
    /* background: url("../images/services.jpg") fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff; */
    height: 100vh;
    margin: 0;
  }
  .seventh_jumbotronBackGroundImage {
    left: 0;
    height: 100vh;
    width: 100%;
    background: url("../images/dark_pattern.png");
    background-position: 0px 0px;
    background-size: auto;
  }
  .seventhSectionNumLogo {
    font-family: "Libre Baskerville", serif !important;
    width: 8%;
    margin: 5% 47.5%;
  }
  .seventhSectionNumClass {
    margin-left: -41%;
    padding-top: 0;
    color: white;
  }
  .formTopCover {
    /* border: 1px solid white; */
    margin-left: 4%;
  }
  .formTopHeader {
    /* border: 1px solid white; */
    color: #c8ac48;
  }
  .formTopPara {
    /* border: 1px solid white; */
    color: grey;
    font-family: sans-serif;
    font-size: 20px;
  }
  .contact_form {
    /* border: 1px solid white; */
    padding: 5%;
    padding-top: 0%;
  }
  .contact_form input,
  .contact_form textarea,
  .contact_form select {
    font-family: sans-serif;
    padding-left: 0%;
    margin: 10px;
    background-color: inherit;
    border: none;
    border-bottom: 2.5px solid #c8ac48;
    border-radius: 0%;
    color: white;
  }
  .contact_form input:focus,
  .contact_form textarea:focus,
  .contact_form select:focus {
    background-color: inherit;
    color: white;
    outline: none;
    border-color: #c8ac48;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .seventhFormImg {
    margin: -3% ;
    width: 93%;
  }
  .FormButtonCover{
    /* border: 1px solid black; */
    width: 30%; 
  }



  @media screen and (max-width: 991px) {
    .seventhFormImg {
        margin: 0 auto !important;
        width: 100%;
        padding-bottom: 0vh;
      }

  .seventh_jumbotron {
    height: max-content;
  }
  .seventh_jumbotronBackGroundImage {
    height: max-content;
  }
  .FormButtonCover{
    /* border: 1px solid black;  */
    width: 50%; 
  }
  
}
@media screen and (max-width: 768px) {
    .FormButtonCover {
        width: 35%;
      }
}
@media screen and (max-width: 480px) {
    .FormButtonCover {
        width: 50%;
      }
}